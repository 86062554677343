/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MarketplaceProfessionalExportResponseData } from '../models/MarketplaceProfessionalExportResponseData';
import type { MarketplaceProfessionalListRequest } from '../models/MarketplaceProfessionalListRequest';
import type { MarketplaceProfessionalListResponseData } from '../models/MarketplaceProfessionalListResponseData';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MarketplaceApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Returns a list of marketplace avaible professionals
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public marketplaceProfessionalList(
    requestBody: MarketplaceProfessionalListRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: Array<MarketplaceProfessionalListResponseData>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/marketplace/professional/list',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Server error`,
      },
    });
  }

  /**
   * Exports a professional data
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public marketplaceProfessionalExport(
    requestBody: {
      professionalId: string;
    },
  ): CancelablePromise<{
    success: boolean;
    data?: MarketplaceProfessionalExportResponseData;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/marketplace/professional/export',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        500: `Server error`,
      },
    });
  }

}
