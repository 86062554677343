/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfessionalsStatusesCountData = {
  properties: {
    ALL: {
      type: 'number',
      isRequired: true,
    },
    REGISTERING: {
      type: 'number',
      isRequired: true,
    },
    ACTION_PENDING: {
      type: 'number',
      isRequired: true,
    },
    WAITING_APPROVAL: {
      type: 'number',
      isRequired: true,
    },
    WAITING_COMPLIANCE: {
      type: 'number',
      isRequired: true,
    },
    WAITING_SYNCHRONIZATION: {
      type: 'number',
      isRequired: true,
    },
    ENABLED: {
      type: 'number',
      isRequired: true,
    },
    DISABLED: {
      type: 'number',
      isRequired: true,
    },
    WAITING_PRE_APPROVAL: {
      type: 'number',
      isRequired: true,
    },
    PRE_APPROVED: {
      type: 'number',
      isRequired: true,
    },
  },
} as const;
