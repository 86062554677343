/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MarketplaceProfessionalListResponseData = {
  properties: {
    uuid: {
      type: 'string',
      isRequired: true,
    },
    cpf: {
      type: 'string',
      isRequired: true,
    },
    cnpj: {
      type: 'string',
      isRequired: true,
    },
    providerId: {
      type: 'string',
      isRequired: true,
    },
    name: {
      type: 'string',
      isRequired: true,
    },
    updatedAt: {
      type: 'string',
      isRequired: true,
    },
    register: {
      type: 'ProfessionalRegister',
    },
    specialties: {
      type: 'array',
      contains: {
        type: 'string',
      },
      isRequired: true,
    },
    professionId: {
      type: 'Profession',
      isRequired: true,
    },
    professionDisplayName: {
      type: 'string',
      isRequired: true,
    },
    registerNumber: {
      type: 'string',
    },
    registerUF: {
      type: 'string',
    },
  },
} as const;
